import { create } from 'zustand';

type SettingsStore = {
  themeMode: 'dark' | 'light';
  toggleThemeMode: () => void;
};
export const useSettingsStore = create<SettingsStore>()((set) => ({
  themeMode: 'light',
  toggleThemeMode: () =>
    set((state) => ({
      themeMode: state.themeMode === 'dark' ? 'light' : 'dark'
    }))
}));
