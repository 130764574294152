import { Theme, alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function DateCalendar(theme: Theme) {
  return {
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          '& .MuiPickersYear-root': {
            flexBasis: '25%'
          },
          '& .MuiPickersYear-yearButton': {
            fontSize: '16px',
            fontFamily: 'TTHoves-Medium',
            padding: '0px',
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: alpha(theme.palette.info.dark, 0.3)
            },
            margin: '5px 0px'
          },
          '& .MuiYearCalendar-root': {
            maxHeight: '240px'
          },
          '& .MuiPickersYear-yearButton.Mui-selected': {
            fontSize: '16px',
            fontFamily: 'TTHoves-Medium',
            color: theme.palette.text.primary,
            padding: '0px',
            backgroundColor: alpha(theme.palette.info.dark, 0.3),
            borderRadius: '8px'
          },
          '& .MuiPickersDay-root': {
            '&:hover': {
              backgroundColor: alpha(theme.palette.info.dark, 0.3)
            }
          },
          '& .MuiPickersDay-root.Mui-selected': {
            backgroundColor: alpha(theme.palette.info.dark, 0.3),
            color: theme.palette.text.primary
          },
          '& .MuiPickersDay-root.Mui-disabled:not(.Mui-selected)': {
            color: alpha(theme.palette.info.dark, 0.5)
          }
        }
      }
    }
  };
}
