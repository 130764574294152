import { Box, Grid } from '@mui/material';
import { motion as Motion } from 'framer-motion';
import Icon from './Icon';

// ----------------------------------------------------------------------

export default function LoadingScreen() {
  return (
    <Grid
      container
      sx={{
        height: '100vh',
        width: '100vw',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        top: '0%',
        left: '0%'
      }}
    >
      <Box sx={{ transform: 'translate(0%%, -100%)' }}>
        <Motion.div
          initial={{ rotateZ: 0 }}
          animate={{ rotateZ: 360 }}
          transition={{
            duration: 1,
            ease: 'easeInOut',
            repeatDelay: 0.3,
            repeat: Infinity
          }}
        >
          <Icon />
        </Motion.div>
      </Box>
    </Grid>
  );
}
