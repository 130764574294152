import { ApolloProvider } from '@apollo/client';
import CssBaseline from '@mui/material/CssBaseline';
import { LicenseInfo } from '@mui/x-license-pro';
import { AnalyticsBrowser } from '@segment/analytics-next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { Router } from 'next/router';
import NProgress from 'nprogress';
import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { hotjar } from 'react-hotjar';
import { IntercomProvider } from 'react-use-intercom';
import { PersistGate } from 'redux-persist/integration/react';
import { ENVIRONMENT, TOKENS } from 'src/config';
import { persistor } from '../src/archived/redux/store';
import LoadingScreen from '../src/components/core/atoms/LoadingScreen';
import NotistackProvider from '../src/components/core/atoms/NotistackProvider';
import useApollo from '../src/hooks/useApollo';
import ThemeConfig from '../src/theme';
import { IntercomUtilsConfigurator } from '../src/utils/intercom';
import '../styles/customGraphiql.css';
import '../styles/globals.css';
import '../styles/nprogress.css';
import '../styles/output.css';
import '../styles/search.css';

NProgress.configure({ showSpinner: false });
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

// ----------------------------------------------------------------------

export const analytics = AnalyticsBrowser.load({
  writeKey:
    ENVIRONMENT == 'production'
      ? 'V1dqRfdNDYKKLE5H5s4sdKqf25ihtbcT'
      : ''
});

// ----------------------------------------------------------------------

// MUI license key for @mui/x-date-pickers-pro
LicenseInfo.setLicenseKey('');

// ----------------------------------------------------------------------

const INTERCOM_APP_ID = 'uxf9i0l2';

export default function App({ Component, pageProps }: AppProps) {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles?.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
    // initialize Hotjar
    hotjar.initialize(4971284, 6);
  }, []);

  const apolloClient = useApollo();

  const app = (
    <HelmetProvider>
      <script
        async
        src={`https://maps.googleapis.com/maps/api/js?key=${TOKENS.GOOGLE_AUTOCOMPLETE_API_KEY}&loading=async&libraries=places&callback=Function.prototype`}
      ></script>
      {/* <style>{'body { background-color:  #161619; }'}</style> */}
      <ApolloProvider client={apolloClient}>
        <PersistGate
          loading={<LoadingScreen />}
          persistor={persistor}
        >
          <ThemeConfig>
            <NotistackProvider>
              <>
                <Head>
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, shrink-to-fit=no"
                  />
                </Head>
                <CssBaseline enableColorScheme />
                <IntercomProvider appId={INTERCOM_APP_ID}>
                  <IntercomUtilsConfigurator />
                  <Component {...pageProps} />
                </IntercomProvider>
              </>
            </NotistackProvider>
          </ThemeConfig>
        </PersistGate>
      </ApolloProvider>
    </HelmetProvider>
  );

  return app;
}
