import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

//eslint-disable-next-line
export default function TableCell(theme: Theme) {
  return {
    MuiTableCell: {
      styleOverrides: {
        root: {
          '&:first-of-type': {
            pl: 0,
            borderRadius: 10
          },
          '&:last-of-type': {
            pr: 0,
            borderRadius : 10
          },
          backgroundColor : 'background.paper',
          border : 0
          // '&:hover': { backgroundColor : theme.palette.background.paper},
        }
      }
    }
  };
}
