import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

//eslint-disable-next-line
export default function SvgIcon(theme: Theme) {
  return {
    MuiSvgIcon: {
      styleOverrides: {
        /* root: {
          stroke: '#343436',
          strokeWidth: 1
        }, */
        fontSizeSmall: {
          width: 20,
          height: 20,
          fontSize: 'inherit'
        },
        fontSizeLarge: {
          width: 32,
          height: 32,
          fontSize: 'inherit'
        }
      }
    }
  };
}
