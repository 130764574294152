import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  ChipProps,
  Chip as MChip,
  Typography
} from '@mui/material';
import { alpha, useTheme } from '@mui/system';
import { SCOPE_COLORS } from 'src/config';
import { useSettingsStore } from 'src/hooks/store/useSettingsStore';

// ----------------------------------------------------------------------

export type ChipColorType =
  | 'primary'
  | 'secondary'
  | 'warning'
  | 'amber'
  | 'error'
  | 'info'
  | 'white'
  | 'border'
  | '1' //scope 1
  | '2' //scope 2
  | '3'; //scope 3

export default function Chip({
  label,
  color: colorVar = 'primary',
  size = 'small',
  onClick = null,
  ignoreTextTransform = false,
  isSelected = null,
  onDelete = null,
  disabled = false,
  includeDropdown = false,
  sx = {},
  props
}: {
  label: string | JSX.Element;
  color?: ChipColorType;
  disabled?: boolean;
  size?: 'mini' | 'small' | 'medium';
  type?: 'attribute' | 'scope' | 'unit';
  onClick?: (isSelected) => void;
  ignoreTextTransform?: boolean;
  isSelected?: boolean;
  onDelete?: () => void;
  includeDropdown?: boolean;
  sx?: object;
  props?: ChipProps;
}) {
  const theme = useTheme();
  // const themeMode = useSettingsStore((state) => state.themeMode);
  //default primary colors
  let backgroundColor = 'transparent';
  let borderColor = 'transparent';
  let color = null;

  switch (colorVar) {
    case 'primary':
      color = theme.palette.primary.main;
      break;
    case 'secondary':
      color = theme.palette.secondary.main;
      break;
    case 'white':
      color = theme.palette.common.white;
      break;
    case 'info':
      color = theme.palette.text.primary;
      break;
    case 'warning':
      color = theme.palette.warning.main;
      break;
    case 'amber':
      color = theme.palette.warning.dark;
      break;
    case 'error':
      color = theme.palette.error.main;
      break;
    case 'border':
      color = theme.palette.border.main;
      break;
    case '1':
      color = SCOPE_COLORS['1'];
      break;
    case '2':
      color = SCOPE_COLORS['2'];
      break;
    case '3':
      color = SCOPE_COLORS['3'];
      break;
    default:
      color = theme.palette.text.primary;
      break;
  }

  // Set background color as alpha of color if no onClick
  backgroundColor = alpha(color, 0.04);

  if ((onClick && isSelected === null) || (onClick && isSelected)) {
    borderColor = alpha(color, 0.5);
  }

  return (
    <MChip
      variant="filled"
      component="span"
      disabled={disabled}
      label={
        <Box sx={{ display: 'flex' }}>
          <Typography
            variant="body2"
            sx={{
              color: color,
              textTransform: ignoreTextTransform
                ? 'none'
                : 'lowercase',
              textAlign: 'center',
              alignItems: 'center',
              display: 'flex',
              mr: 0
            }}
          >
            {label}
          </Typography>
          {includeDropdown && (
            <ExpandMoreIcon
              color={color}
              sx={{ color: color, transform: 'scale(0.65)' }}
            />
          )}
        </Box>
      }
      {...props}
      onDelete={
        onDelete
          ? () => {
              onDelete();
            }
          : null
      }
      size={size === 'mini' ? 'small' : size}
      sx={{
        border: 1,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        py: size === 'mini' ? 1 : 2,
        px: size === 'mini' ? 0.5 : 1,
        pb: size === 'mini' ? 1.2 : 2,
        transition: 'all 0.2s ease-in-out',
        boxShadow: onClick ? 0 : 0,
        '.MuiTouchRipple-child': {
          backgroundColor: alpha(color, 0.5)
        },
        ...sx,
        '&:hover': onClick
          ? {
              backgroundColor: backgroundColor,
              borderColor: alpha(color, 0.5),
              cursor: 'pointer'
            }
          : {}
      }}
      clickable={onClick ? true : false}
      onClick={
        onClick
          ? () => {
              onClick(!isSelected);
            }
          : null
      }
    />
  );
}

export function SpecialChip({
  label,
  type,
  size = 'small',
  onClick = null,
  selected = false,
  disabled = false,
  isLowerCase = false,
  sx = {}
}: {
  label: string;
  type: 'image' | 'b&w' | 'imports' | 'blog';
  size?: 'small' | 'medium';
  onClick?: () => void;
  selected?: boolean;
  color?: string;
  disabled?: boolean;
  sx?: object;
  isLowerCase?: boolean;
}) {
  const theme = useTheme();
  const themeMode = useSettingsStore((state) => state.themeMode);
  const lower = label?.toLowerCase();
  let variant: 'outlined' | 'filled' = 'outlined';
  let labelProp = (
    <Typography
      variant="overline"
      color={selected ? 'background.default' : 'primary.main'}
    >
      {isLowerCase ? lower : label}
    </Typography>
  );

  switch (type) {
    case 'image':
      labelProp = (
        <Typography
          variant={isLowerCase ? 'subtitle1' : 'overline'}
          color="common.white"
        >
          {label}
        </Typography>
      );
      sx = {
        ...sx,
        border: 1,
        borderColor: 'common.white',
        background: 'rgba(128,128,128,0.5)',
        backdropFilter: 'saturate(180%) blur(5px)'
      };
      break;

    case 'b&w':
      variant = 'filled';
      labelProp = (
        <Typography
          variant="overline"
          color={theme.palette.common.black}
        >
          {label}
        </Typography>
      );
      sx = {
        ...sx,
        border: 2,
        borderColor: 'common.black',
        backgroundColor: 'white',
        color: theme.palette.background.neutral
      };
      break;

    case 'blog':
      variant = 'filled';
      labelProp = (
        <Typography
          variant="overline"
          color={
            selected
              ? themeMode == 'light'
                ? theme.palette.common.white
                : theme.palette.common.dark
              : theme.palette.info.light
          }
        >
          {label}
        </Typography>
      );
      sx = {
        ...sx,
        border: 2,
        borderColor: selected
          ? theme.palette.secondary.dark
          : theme.palette.background.neutral,
        backgroundColor: selected
          ? theme.palette.secondary.dark
          : theme.palette.background.neutral,
        '&:hover': {
          backgroundColor: selected
            ? theme.palette.secondary.dark
            : theme.palette.common.white
        }
      };
      break;
  }

  return (
    <MChip
      size={size}
      onClick={onClick}
      variant={variant}
      label={labelProp}
      disabled={disabled}
      sx={{
        py: 2,
        ...sx
      }}
    />
  );
}
