import type { Config } from "tailwindcss";

export const EMISSION_CATEGORY_COLOURS = {
  energy: '#fa8845',
  upstream: '#8f36e7',
  operations: '#44a250',
  downstream: '#6854ff',
  other: '#952424'
}
export const TOPBAR_HEIGHT = 55;
export const LEFT_SIDEBAR_WIDTH = {
  FULL: 230,
  MINIMIZED: 32
};
export const ICON_SIZE = {
  SM: 12,
  DEFAULT: 16
};
export const RIGHT_SIDEBAR_WIDTH = 230;
export const SUBNAV_HEIGHT = TOPBAR_HEIGHT;
export const PANEL = {
  DEFAULT: 400,
  MIN: 300,
  MAX: 700
}

const widths = {
  'left-sidebar': LEFT_SIDEBAR_WIDTH.FULL + 'px',
  'right-sidebar': RIGHT_SIDEBAR_WIDTH + 'px',
  'left-sidebar-page': `calc(100vw - ${LEFT_SIDEBAR_WIDTH.FULL + 'px'})`,
  'double-sidebar-page': `calc(100vw - ${LEFT_SIDEBAR_WIDTH.FULL + RIGHT_SIDEBAR_WIDTH + 'px'})`,
  'left-min-sidebar': LEFT_SIDEBAR_WIDTH.MINIMIZED + 'px',
  'left-min-sidebar-page': `calc(100vw - ${LEFT_SIDEBAR_WIDTH.MINIMIZED + 'px'})`,
  'double-min-sidebar-page': `calc(100vw - ${LEFT_SIDEBAR_WIDTH.MINIMIZED + RIGHT_SIDEBAR_WIDTH + 'px'})`,
  panel: PANEL.DEFAULT + 'px',
  'panel-min': PANEL.MIN + 'px',
  'panel-max': PANEL.MAX + 'px',
  icon: ICON_SIZE.DEFAULT + 'px',
  'icon-sm': ICON_SIZE.SM + 'px',
};

export const heights = {
  subnav: SUBNAV_HEIGHT + 'px',
  'small-button': '1.75rem', 
  topbar: TOPBAR_HEIGHT + 'px',
  'page-height': `calc(100vh - ${TOPBAR_HEIGHT + 'px'})`,
  'page-height-subnav': `calc(100vh - ${TOPBAR_HEIGHT + 'px'} - ${SUBNAV_HEIGHT + 'px'})`,
  icon: ICON_SIZE.DEFAULT + 'px',
  'icon-sm': ICON_SIZE.SM + 'px',
  row: '40px'
}

const config = {
  darkMode: ["class"],
  content: [
    './pages/**/*.{ts,tsx}',
    './components/**/*.{ts,tsx}',
    './app/**/*.{ts,tsx}',
    './src/**/*.{ts,tsx}',
    './@/**/*.{ts,tsx}',
	],
  prefix: "",
  theme: {
    container: {
      center: true,
      padding: "2rem",
      screens: {
        "2xl": "1400px",
      },
    },
    extend: {
      spacing: {
        sm: '8px',
        md: '16px',
        lg: '24px',
        xl: '48px',
        '2xl': '96px',
        '4xl': '180px',
        topbar: TOPBAR_HEIGHT + 'px',
        sidebar: LEFT_SIDEBAR_WIDTH.FULL + 'px'
      },
      backgroundImage: {
        'sustainability-header': "url(/static/landingpage/gradient.png)",
      },
      zIndex: {
        page: '400',
        topbar: '1000',
        subnav: '900',
        'dropdown-menu': '2000',
        panel: '900',
        dropzone: '11'
      },
      minWidth: {
        ...widths
      },
      width: {
        ...widths,
      },
      maxWidth: {
        paragraph: '37rem',
        ...widths
      },
      minHeight: {
        ...heights
      },
      height: {
        ...heights
      },
      maxHeight: {
        ...heights
      },
      colors: {
        border: "hsl(var(--border))",
        input: "hsl(var(--input))",
        ring: "hsl(var(--ring))",
        background: "hsl(var(--background))",
        foreground: "hsl(var(--foreground))",
        warning: {
          DEFAULT: "hsl(var(--warning))",
          foreground: "hsl(var(--warning-foreground))"
        },
        primary: {
          DEFAULT: "hsl(var(--primary))",
          foreground: "hsl(var(--primary-foreground))",
        },
        secondary: {
          DEFAULT: "hsl(var(--secondary))",
          foreground: "hsl(var(--secondary-foreground))",
        },
        destructive: {
          DEFAULT: "hsl(var(--destructive))",
          foreground: "hsl(var(--destructive-foreground))",
        },
        success: {
          DEFAULT: "hsl(var(--success))",
          foreground: "hsl(var(--success-foreground))",
        },
        muted: {
          DEFAULT: "hsl(var(--muted))",
          foreground: "hsl(var(--muted-foreground))",
        },
        accent: {
          DEFAULT: "hsl(var(--accent))",
          foreground: "hsl(var(--accent-foreground))",
        },
        popover: {
          DEFAULT: "hsl(var(--popover))",
          foreground: "hsl(var(--popover-foreground))",
        },
        card: {
          DEFAULT: "hsl(var(--card))",
          foreground: "hsl(var(--card-foreground))",
        },
        'scope-1': {
          DEFAULT: "hsl(var(--scope-1))",
          foreground: "hsl(var(--scope-1-foreground))",
        },
        'scope-2': {
          DEFAULT: "hsl(var(--scope-2))",
          foreground: "hsl(var(--scope-2-foreground))",
        },
        'scope-3': {
          DEFAULT: "hsl(var(--scope-3))",
          foreground: "hsl(var(--scope-3-foreground))",
        },
        energy: EMISSION_CATEGORY_COLOURS.energy,
        upstream: EMISSION_CATEGORY_COLOURS.upstream,
        operations: EMISSION_CATEGORY_COLOURS.operations,
        downstream: EMISSION_CATEGORY_COLOURS.downstream,
        other: EMISSION_CATEGORY_COLOURS.other
      },
      borderRadius: {
        lg: "var(--radius)",
        md: "calc(var(--radius) - 2px)",
        sm: "calc(var(--radius) - 4px)",
      },
      keyframes: {
        "accordion-down": {
          from: { height: "0" },
          to: { height: "var(--radix-accordion-content-height)" },
        },
        "accordion-up": {
          from: { height: "var(--radix-accordion-content-height)" },
          to: { height: "0" },
        },
      },
      animation: {
        "accordion-down": "accordion-down 0.2s ease-out",
        "accordion-up": "accordion-up 0.2s ease-out",
      },
    },
  },
  plugins: [require("tailwindcss-animate")],
} as Config;

export default config;