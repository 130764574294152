import { Box } from '@mui/material';
import { useRef } from 'react';
import { getTailwindTheme } from 'src/config';

export default function EditableValueDisplay({
  children,
  prefixText = null,
  tabIndex = 0,
  warningStatement = null,
  highlight = false,
  onClick = null,
  onMouseEnter = null,
  disabled = false,
  backgroundColor = 'background.paper',
  onMouseLeave = null
}: {
  children: React.ReactNode;
  prefixText?: string | null;
  tabIndex?: number;
  warningStatement?: boolean | null;
  highlight?: boolean;
  onClick?: (e) => void;
  onMouseEnter?: (e) => void;
  disabled?: boolean;
  backgroundColor?: string;
  onMouseLeave?: (e) => void;
}) {
  const theme = getTailwindTheme();
  const textColor = highlight ? 'primary.main' : 'text.primary';
  const ref = useRef(null);

  return (
    <Box
      ref={ref}
      tabIndex={tabIndex}
      onClick={
        disabled
          ? null
          : (e) => {
              onClick && onClick(e);
            }
      }
      onMouseEnter={
        disabled
          ? null
          : (e) => {
              onMouseEnter && onMouseEnter(e);
            }
      }
      onMouseLeave={
        disabled
          ? null
          : (e) => {
              onMouseLeave && onMouseLeave(e);

              // if ref is focused, unfocus
              if (ref.current === document.activeElement) {
                ref.current.blur();
              }
            }
      }
      // If the element is focused, and the user presses enter, the onClick function will be called.
      onKeyDown={
        disabled
          ? null
          : (e) => {
              // check if element is focused

              if (e.key === 'Enter') {
                onClick && onClick(e);
              }
            }
      }
      sx={{
        border: 1,
        borderRadius: 1,
        px: 2,
        fontWeight: 'caption.fontWeight',
        fontSize: 'caption.fontSize',
        fontFamily: 'caption.fontFamily',
        color: disabled
          ? 'text.primaryAlt2'
          : warningStatement === true
          ? 'warning.dark'
          : textColor,
        borderColor: 'border.main',
        backgroundColor: backgroundColor,
        cursor: disabled ? 'inherit' : 'pointer',
        maxHeight: theme.height['small-button'],
        alignItems: 'center',
        justifyContent: 'center',
        height: theme.height['small-button'],
        transition: 'all 0.2s ease-in-out',
        '&:hover': !disabled && {
          color: warningStatement === true ? 'warning.dark' : textColor,
          borderColor: 'info.main'
        },
        display: 'flex',
        '&:focus': disabled
          ? null
          : {
              outline: 'none',
              borderColor: 'info.main'
              // boxShadow: 10
            }
      }}
    >
      {prefixText ? (
        <Box sx={{ display: 'inline', color: 'text.primaryAlt2' }}>
          {prefixText}:&nbsp;
        </Box>
      ) : (
        ''
      )}
      {children}
    </Box>
  );
}
