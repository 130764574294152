import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Tabs(theme: Theme) {
  return {
    MuiTab: {
      styleOverrides: {
        root: {
          borderTopLeftRadius: theme.shape.borderRadius,
          borderTopRightRadius: theme.shape.borderRadius
        }
      }
    }
  };
}
