import AnnouncementIcon from '@mui/icons-material/Announcement';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import { Box, IconButton } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Theme, useTheme } from '@mui/system';
import { SnackbarProvider } from 'notistack';
import React, { ReactNode, createRef } from 'react';
import { ColorSchema } from 'src/@types/theme';
import { SnackbarUtilsConfigurator } from 'src/utils/snackbar';

// ----------------------------------------------------------------------

function SnackbarIcon({
  icon,
  color
}: {
  icon: React.ReactElement;
  color: ColorSchema;
}) {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        color: `${color}.main`,
        bgcolor: (theme: Theme) => alpha(theme.palette[color].main, 0.32)
      }}
    >
      {icon}
    </Box>
  );
}

function NotistackProvider({ children }: { children: ReactNode }) {
  const notistackRef: React.Ref<SnackbarProvider> = createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  const theme = useTheme();

  return (
    <SnackbarProvider
      maxSnack={5}
      preventDuplicate
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      iconVariant={{
        success: <SnackbarIcon icon={<CheckCircleIcon />} color="success" />,
        error: <SnackbarIcon icon={<ErrorIcon />} color="error" />,
        warning: <SnackbarIcon icon={<AnnouncementIcon />} color="warning" />,
        info: <SnackbarIcon icon={<InfoIcon />} color="info" />
      }}
      classes={{}}
      ref={notistackRef}
      action={(key) => (
        <IconButton
          onClick={onClickDismiss(key)}
          sx={{
            '&:hover': {
              backgroundColor: alpha(theme.palette.text.primary, 0.16)
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    >
      <SnackbarUtilsConfigurator />
      {children}
    </SnackbarProvider>
  );
}

export default NotistackProvider;
