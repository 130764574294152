import { format, formatDistanceToNow } from 'date-fns';
import dayjs from 'dayjs';
import _ from 'lodash';
import { replace } from 'lodash';
import numeral from 'numeral';
import { UnitsEnum } from 'src/__apolloGenerated__/graphql';
import { UnitLabels } from 'src/backend/@types';
import { INSTITUTION_INFO, TICKER_INFO, TOPBAR_HEIGHT } from 'src/config';
import { v5 as uuidv5 } from 'uuid';

// ----------------------------------------------------------------------

export function split(str, index) {
  const result = [str.slice(0, index), str.slice(index)];

  return result;
}

export function getListItemKey(input) {
  return uuidv5(String(input), uuidv5.URL);
}

export function remToPx(value: string) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value: number) {
  return `${value / 16}rem`;
}

// NUMBERS CURRENCY

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  if (number === '0E-10' || number === undefined || number === null) {
    return 0;
  }
  return numeral(number).format('0,0.00');
}

export function fShortenNumber(number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fPrecision(number, precision = 0) {
  return number ? Number(Number(number).toFixed(precision)) / 1 : 0; // divide by 1 to trim trailing zeros
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function getPrecision(number) {
  const index = String(number).indexOf('.') + 1;
  return number && index > 0 ? String(number).length - index : 0;
}

export function fUnit(unit: UnitsEnum) {
  return UnitLabels[unit];
}

// ENUM_VALUE to Enum Value
export function fEnum(enumValue: string){
  return enumValue !== null && enumValue !== undefined ? _.startCase(enumValue.toLowerCase()) : '';
}

// DATE TIME

// 07 September 2023
export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

// Sep 2023
export function fProjectDate(date) {
  return format(new Date(date), 'MMM yyyy');
}

// September 2023
export function fLocalMonthYear(date, timeZone = null) {
  return date
    ? new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        ...(timeZone ? { timeZone } : {})
      })
    : null;
}

// Wed, Nov 1
export function fLocalMonthDay(date, timeZone = null) {
  return new Date(date).toLocaleDateString('en-US', {
    month: 'short',
    weekday: 'short',
    day: '2-digit',
    ...(timeZone ? { timeZone } : {})
  });
}

// September
export function fLocalMonth(date, timeZone = null) {
  return date
    ? new Date(date).toLocaleDateString('en-US', {
        month: 'long',
        ...(timeZone ? { timeZone } : {})
      })
    : null;
}

// Monday
export function fLocalDay(date) {
  return new Date(date).toLocaleDateString('en-US', {
    weekday: 'long'
  });
}

// September 07, 2023
export function fLocalDate(date, timeZone = null) {
  return date
    ? new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        ...(timeZone ? { timeZone } : {})
      })
    : null;
}

// Sep 07, 2023
export function fLocalDateShort(date) {
  return date
    ? new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: '2-digit'
      })
    : null;
}

// 12:00 AM
export function fTime(date) {
  return format(new Date(date), 'p');
}

// 12:00:00 AM
export function fLocalTime(date) {
  return new Date(date).toLocaleTimeString('en-US');
}

// 07 Sep 2023 00:00
export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

// 07/09/2023 12:00 12:00 AM
export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

// 07/09/2023
export function fDateTimeShort(date) {
  return format(new Date(date), 'dd/MM/yyyy');
}

// 5 days ago [if today is September 12 and the date is September 7]
export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

// CALENDAR

export function getLastDayOfMonth(year: number, month: number) {
  const date = new Date(year, month + 1, 0);
  return date.getDate();
}

/*
  @month is not an index but a calendar number,
  ie., 1 - January, 2 - February
*/
export function daysInMonth(year: number, monthNumber: number) {
  return new Date(year, monthNumber, 0).getDate();
}
/* http://stackoverflow.com/questions/41068969/ddg#41069058
In the Gregorian calendar there are three criteria to identify leap years:
- The year can be evenly divided by 4.
- If the year can be evenly divided by 100, it is NOT a leap year, unless;
- The year is also evenly divisible by 400. Then it is a leap year.
*/
export function daysInYear(year) {
  return ((year % 4 === 0 && year % 100 > 0) || year %400 == 0) ? 366 : 365;
}

// returns a specified date as a UTC dayjs object with hours, minutes, seconds, milliseconds set to zero
export function utcNormalizedDate(date: string | Date | dayjs.Dayjs) {
  return dayjs(date).utc().hour(0).minute(0).second(0).millisecond(0);
}

// CURRENCY BANK IMAGES

export function tickerImage(ticker) {
  return TICKER_INFO[ticker]?.image;
}

export function tickerName(ticker) {
  return TICKER_INFO[ticker]?.name;
}

export function institutionImage(institution) {
  return INSTITUTION_INFO[institution]?.image;
}

export function institutionName(institution) {
  return INSTITUTION_INFO[institution]?.name;
}

// MISC.

export function getHeight() {
  return document.body.clientHeight - TOPBAR_HEIGHT + 'px';
}

export function getDomain(data) {
  const numbers = [];
  data?.map((slice) => {
    numbers.push(Math.floor(Number(slice.exchangeRate)));
  });
  const min = Math.min(...numbers);
  const max = Math.max(...numbers);

  return [
    Math.floor(min - (max - min) * 0.2),
    Math.floor(max + (max - min) * 0.2)
  ];
}

//formats integer text inputs
export function formatInput(input: string) {
  if (input) {
    //remove all non-numeric characters
    input = input.replace(/[^0-9.]/g, '');

    if (input === '') return input; //if there's nothing left after removing non-numeric chars

    //ensure there is only one decimal point
    if (input.split('.').length > 2) {
      const decimal = input.split('.').slice(1);
      input = input.split('.')[0] + '.' + decimal.join('');
    }

    //find index of decimal point and format number
    const decimalIdx = input.indexOf('.');
    if (decimalIdx != -1) {
      const [integer, decimalPlaces] = split(input, decimalIdx);
      return String(fNumber(integer)) + String(decimalPlaces.slice(0, 3));
    } else {
      return String(fNumber(input));
    }
  }
  return input;
}

export function simplifyNumber(input: string) {
  return input.replace(/[^0-9.]/g, '');
}

export const getUrlPath = (card: string, idx: number) => {
  if (card == 'block2') {
    return `url("/static/landingpage/blocks/block2/00${String(idx).padStart(
      3,
      '0'
    )}.png")`;
  }
  if (card == 'block3') {
    return `url("/static/landingpage/blocks/block3/00${String(idx).padStart(
      3,
      '0'
    )}.png")`;
  }
  return '';
};

export const capitalizeFirstLetter = (input: string) => {
  return input?.charAt(0)?.toUpperCase() + input?.slice(1)?.toLowerCase();
};

export function formatFileSize(bytes, decimalPoint) {
  if (bytes == 0) return '0 Bytes';
  const k = 1000,
    dm = decimalPoint || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return (
    parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  );
}
