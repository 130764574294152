import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

//eslint-disable-next-line
export default function TableRow(theme: Theme) {
  return {
    MuiTableRow: {
      styleOverrides: {
        root: {
        },

      }
    }
  };
}
