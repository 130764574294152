import { alpha } from '@mui/material/styles';
import { Shadows } from '@mui/material/styles/shadows';
import palette from './palette';

// ----------------------------------------------------------------------

interface CustomShadowOptions {
  z1: string;
  z8: string;
  z12: string;
  z16: string;
  z20: string;
  z24: string;
  //
  primary: string;
  secondary: string;
  info?: string;
  success?: string;
  warning?: string;
  error?: string;
  //
  card: string;
  dialog: string;
  dropdown: string;
}

declare module '@mui/material/styles' {
  interface Theme {
    customShadows: CustomShadowOptions;
  }
  interface ThemeOptions {
    customShadows?: CustomShadowOptions;
  }
}

const LIGHT_MODE = '#dddddd';
const DARK_MODE = palette.dark.common.black

const createShadow = (color: string): Shadows => {
  const transparent1 = alpha(color, 0.84);
  const transparent2 = alpha(color, 0.44);
  const transparent3 = alpha(color, 0.22);
  return [
    'none',
    `0px 0px 1px -1px ${transparent1},0px 0px 1px 0px ${transparent2},0px 0px 3px 0px ${transparent3}`,
    `0px 0px 1px -2px ${transparent1},0px 0px 2px 0px ${transparent2},0px 0px 5px 0px ${transparent3}`,
    `0px 0px 3px -2px ${transparent1},0px 0px 4px 0px ${transparent2},0px 0px 8px 0px ${transparent3}`,
    `0px 0px 4px -1px ${transparent1},0px 0px 5px 0px ${transparent2},0px 0px 10px 0px ${transparent3}`,
    `0px 0px 5px -1px ${transparent1},0px 0px 8px 0px ${transparent2},0px 0px 14px 0px ${transparent3}`,
    `0px 0px 5px -1px ${transparent1},0px 0px 10px 0px ${transparent2},0px 0px 18px 0px ${transparent3}`,
    `0px 0px 5px -2px ${transparent1},0px 0px 10px 1px ${transparent2},0px 0px 16px 1px ${transparent3}`,
    `0px 0px 5px -3px ${transparent1},0px 0px 10px 1px ${transparent2},0px 0px 14px 2px ${transparent3}`,
    `0px 0px 6px -3px ${transparent1},0px 0px 12px 1px ${transparent2},0px 0px 16px 2px ${transparent3}`,
    `0px 0px 6px -3px ${transparent1},0px 0px 14px 1px ${transparent2},0px 0px 18px 3px ${transparent3}`,
    `0px 0px 7px -4px ${transparent1},0px 0px 15px 1px ${transparent2},0px 0px 20px 3px ${transparent3}`,
    `0px 0px 8px -4px ${transparent1},0px 0px 17px 2px ${transparent2},0px 0px 22px 4px ${transparent3}`,
    `0px 0px 8px -4px ${transparent1},0px 0px 19px 2px ${transparent2},0px 0px 24px 4px ${transparent3}`,
    `0px 0px 9px -4px ${transparent1},0px 0px 21px 2px ${transparent2},0px 0px 26px 4px ${transparent3}`,
    `0px 0px 9px -5px ${transparent1},0px 0px 22px 2px ${transparent2},0px 0px 28px 5px ${transparent3}`,
    `0px 0px 10px -5px ${transparent1},0px 0px 24px 2px ${transparent2},0px 0px 30px 5px ${transparent3}`,
    `0px 0px 11px -5px ${transparent1},0px 0px 26px 2px ${transparent2},0px 0px 32px 5px ${transparent3}`,
    `0px 0px 11px -5px ${transparent1},0px 0px 28px 2px ${transparent2},0px 0px 34px 6px ${transparent3}`,
    `0px 0px 12px -6px ${transparent1},0px 0px 29px 2px ${transparent2},0px 0px 36px 6px ${transparent3}`,
    `0px 0px 13px -6px ${transparent1},0px 0px 31px 3px ${transparent2},0px 0px 38px 7px ${transparent3}`,
    `0px 0px 13px -6px ${transparent1},0px 0px 33px 3px ${transparent2},0px 0px 40px 7px ${transparent3}`,
    `0px 0px 14px -6px ${transparent1},0px 0px 35px 3px ${transparent2},0px 0px 42px 7px ${transparent3}`,
    `0px 0px 14px -7px ${transparent1},0px 0px 36px 3px ${transparent2},0px 0px 44px 8px ${transparent3}`,
    `0px 0px 15px -7px ${transparent1},0px 0px 38px 3px ${transparent2},0px 0px 46px 8px ${transparent3}`
  ];
};

const createCustomShadow = (color: string): CustomShadowOptions => {
  const transparent = alpha(color, 0.16);
  return {
    z1: `0 1px 2px 0 ${transparent}`,
    z8: `0 8px 16px 0 ${transparent}`,
    z12: `0 12px 24px -4px ${transparent}`,
    z16: `0 16px 32px -4px ${transparent}`,
    z20: `0 20px 40px -4px ${transparent}`,
    z24: `0 24px 48px 0 ${transparent}`,
    //
    primary: `0 8px 16px 0 ${alpha(palette.light.primary.main, 0.24)}`,
    secondary: `0 8px 16px 0 ${alpha(palette.light.secondary.main, 0.24)}`,
    // info: `0 8px 16px 0 ${alpha(palette.light.info.main, 0.24)}`,
    // success: `0 8px 16px 0 ${alpha(palette.light.success.main, 0.24)}`,
    // warning: `0 8px 16px 0 ${alpha(palette.light.warning.main, 0.24)}`,
    // error: `0 8px 16px 0 ${alpha(palette.light.error.main, 0.24)}`,
    //
    card: `0 0 2px 0 ${alpha(color, 0.2)}, 0 12px 24px -4px ${alpha(
      color,
      0.12
    )}`,
    dialog: `-40px 40px 80px -8px ${alpha(palette.light.common.black, 0.24)}`,
    dropdown: `0 0 2px 0 ${alpha(color, 0.24)}, -20px 20px 40px -4px ${alpha(
      color,
      0.24
    )}`
  };
};

export const customShadows = {
  light: createCustomShadow(LIGHT_MODE),
  dark: createCustomShadow(DARK_MODE)
};

const shadows: {
  light: Shadows;
  dark: Shadows;
} = {
  light: createShadow(LIGHT_MODE),
  dark: createShadow(DARK_MODE)
};

export default shadows;
