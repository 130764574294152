import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Tooltip(theme: Theme) {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'transparent',
          maxWidth: 'none',
          m: 0,
          p: 0
        },
        popper: {
          // backgroundColor: theme.palette.background.paper,
          backgroundColor: 'transparent',
          p: 0
        },
        arrow: {
          color: theme.palette.grey[isLight ? 800 : 700]
        }
      }
    }
  };
}
