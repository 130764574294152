import { Theme } from '@mui/material/styles';
import { CloseIcon } from './CustomIcons';

// ----------------------------------------------------------------------

export default function Chip(theme: Theme) {
  return {
    MuiChip: {
      defaultProps: {
        deleteIcon: <CloseIcon />
      },
      styleOverrides: {
        colorDefault: {
          '& .MuiChip-avatarMedium, .MuiChip-avatarSmall': {
            color: theme.palette.text.primary
          }
        },
        outlined: {
//          borderColor: theme.palette.chip.main,
          // '&.MuiChip-colorPrimary': {
          //   borderColor: theme.palette.primary.main
          // },
          // '&.MuiChip-colorSecondary': {
          //   borderColor: theme.palette.secondary.main
          // },
//          color: theme.palette.chip.main,
          padding: '20px 10px',
          borderRadius: '30px'
        },
        filled: {
//          border: '1px solid '+theme.palette.chip.main,
//          color: theme.palette.chip.contrastText,
          padding: '20px 10px',
          borderRadius: '30px'
        }
      }
    }
  };
}
