import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Accordion(theme: Theme) {
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '&.Mui-focusVisible': {
            backgroundColor: 'transparent'
          },
          '&.Mui-expanded': {
            borderRadius: theme.shape.borderRadius
          },
          '&.Mui-disabled': {
            backgroundColor: 'transparent'
          },
          '&:before': {
            display: 'none'
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&.Mui-focusVisible': {
            backgroundColor: 'transparent'
          },
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          '&.Mui-disabled': {
            opacity: 1,
            color: theme.palette.action.disabled,
            '& .MuiTypography-root': {
              color: 'inherit'
            }
          }
        },
        expandIconWrapper: {
          color: theme.palette.primary.main
        }
      }
    }
  };
}
