import { Theme } from '@mui/material/styles';
import { useSettingsStore } from 'src/hooks/store/useSettingsStore';

export default function IconButton(
  /* eslint-disable @typescript-eslint/no-unused-vars */ theme: Theme
) {
  const themeMode = useSettingsStore((state) => state.themeMode);
  const isLight = themeMode === 'light';
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: isLight ? '#919090' : '#4d4d4d'
          }
        }
      }
    }
  };
}
