import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Button(theme: Theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {},
        sizeLarge: {
          height: 48
        },
        containedInherit: {
          color: theme.palette.grey[800],
          '&:hover': {
            backgroundColor: theme.palette.grey[400]
          }
        },
        containedPrimary: {
          backgroundColor: theme.palette.primary.main
        },
        outlinedPrimary: {
          boxShadow: 'none',
          disabled: {
            color: theme.palette.grey[800]
          }
        },
        outlinedSecondary: { boxShadow: 'none' }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '& .MuiIconButton-root': {
            color: 'inherit'
          },
          '& .Mui-disabled': {
            color: theme.palette.primary.main
          },
          '&:hover': {}
        },
        disabled: {
          color: theme.palette.primary.main
        }
      }
    }
  };
}
