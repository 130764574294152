import { CssBaseline } from '@mui/material';
import {
  ThemeProvider as MUIThemeProvider,
  ThemeOptions,
  createTheme
} from '@mui/material/styles';
import { ReactNode, useMemo } from 'react';
import { useSettingsStore } from 'src/hooks/store/useSettingsStore';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import { getPalette } from './palette';
import shadows, { customShadows } from './shadows';
import { getTypography } from './typography';

// ----------------------------------------------------------------------

export default function ThemeConfig({
  children
}: {
  children: ReactNode;
}) {
  const themeMode = useSettingsStore((state) => state.themeMode);
  const isLight = themeMode === 'light';
  const palette = useMemo(
    () => getPalette({ themeMode: themeMode }),
    [themeMode]
  );
  const typography = useMemo(
    () =>
      getTypography({
        themeMode: themeMode
        //, palette: palette
      }),
    [themeMode]
  );

  const themeOptions: ThemeOptions = useMemo(() => {
    return {
      palette: palette,
      typography: typography,
      breakpoints,
      shape: { borderRadius: 8 },
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight
        ? customShadows.light
        : customShadows.dark
    };
  }, [themeMode]);

  const theme = createTheme(themeOptions as ThemeOptions, [
    themeMode
  ]);
  theme.components = componentsOverride(theme);

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      {themeMode === 'dark' ? (
        <div className="dark">{children}</div>
      ) : (
        children
      )}
    </MUIThemeProvider>
  );
}
